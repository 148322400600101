import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import axios from "axios";
import upLoad from '@/assets/img/screenshoot_upload.png';
import CONFIG from "@/assets/js/config.js";

export default{
  name: "SettingEdit",

  components: {
    MainLayout,
    Loading,
  },

  data() {
    return {
      isLoading: false,
      disabled: false,
      upLoad,
      signImage: null,
      settings: {
        teacher_chat_approval: false,
        signature: null,
        send_notice_notifications: false,
      },
      isFileChange: false,
    };
  },

  computed: {
    ...mapGetters(["getUser"]),
  },

  methods: {
    getSetting() {
      this.isLoading = true;
      axios
        .get("/setting/" + this.$store.state.user.campus_id)
        .then((response) => {
          this.settings.teacher_chat_approval = response.data.teacher_chat_approval == 1 ? true : false;
          this.settings.signature = this.signImage = response.data.signature;
          this.settings.send_notice_notifications = response.data.send_notice_notifications == 1 ? true : false;
          this.isLoading = false;
        })
        .catch((error) => {
            if (error.response && error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
            }
        });
    },

    saveSetting() {
      this.isLoading = true;
      this.disabled = true;
      this.settings.teacher_chat_approval = this.settings.teacher_chat_approval ? 1 : 0;
      this.settings.signature = this.signImage == null ? '' : this.signImage;
      var request = {
          campus_id: this.$store.state.user.campus_id,
          settings: this.settings,
          file_change: this.isFileChange,
      };
      axios
        .post("/setting/save", request)
        .then((response) => {
          if (response.data.success) {
            this.$router.push({name: 'settingPage'});
          }
          this.isLoading = false;
          this.disabled = false;
        })
        .catch((error) => {
            if (error.response && error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
            }
        });
    },

    chooseFiles(id) {
      document.getElementById(id).click();
    },

    onFileChange(e) {
      var selectedFile = e.target.files[0];

      if (selectedFile.type !== "image/png") {
        this.$notification.error("Only PNG file type allowed!", {
          timer: 3,
          position: "bottomCenter",
        });
        return null;
      }

      if (selectedFile.size > CONFIG.IMAGE_MAX_FILE_SIZE) {
        this.$notification.error(CONFIG.IMAGE_MAX_SIZE_ERROR, {
          timer: 3,
          position: "bottomCenter",
        });
        return null;
      }

      let reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => this.$set(this, "signImage", reader.result);
      this.isFileChange = true;
    },

    removeImg(img) {
      this.$set(this, "signImage", null);
      document.getElementById(img).value = '';
      this.isFileChange = true;
    },
  },

  mounted() {
    this.getSetting()
  }

}
