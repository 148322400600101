import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import {
    required,
} from "vuelidate/lib/validators";
import store from '@/store'
import upLoad from '@/assets/img/upload_photo3.jpg';

export default {
    name: "leaveEdit",
    components: {
        MainLayout,
        Select2Multiple,
        flatPickr,
        Loading,
    },

    data() {
        return {
            selectedPeriod: null,
            upLoad,
            periodOptions: [
                { id: '0', text: 'Morning' },
                { id: '1', text: 'Evening' },
                { id: '2', text: 'Full' },
            ],
            leaveData: [],
            submitted: false,
            isLoading: false,
            campusId: store.state.user.campus_master.campus_code,
            disabled: false,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            images: [],
            existingImages: [],
        };
    },

    validations: {
        leaveData: {
            period: {
                required,
            },
            student_name: {
                required,
            },
            teacher_name: {
                required,
            },
            leave_reason: {
                required,
            },
            from_date: {
               required,
            },
            to_date: {
                required
            },
            request_date:{
                required
            },
            status: {
                required
            }
        },
    },

    methods: {
        chooseFiles(id) {
            document.getElementById(id).click();
        },
        onFileChange(e) {
            var selectedFiles = e.target.files;
            if (selectedFiles[0].type.startsWith("image/")) {
                if (selectedFiles.type == "image/gif") {
                    this.$notification.error("File not supported!", {
                        timer: 3,
                        position: "bottomCenter",
                    });
                    return null;
                }
                for (let i = 0; i < selectedFiles.length; i++) {
                    if (selectedFiles[i].size > CONFIG.IMAGE_MAX_FILE_SIZE) {
                        this.$notification.error(CONFIG.IMAGE_MAX_SIZE_ERROR, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                        continue;
                    }
                    let total_img_count = this.images.length + (this.existingImages.length);
                    if (total_img_count > 4) {
                        this.$notification.error("Can only upload a maximum of five images.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    } else {
                        this.images.push(selectedFiles[i]);
                        for (let i = 0; i < this.images.length; i++) {
                            let reader = new FileReader();
                            reader.addEventListener('load', ((index) => {
                                return () => {
                                    let refName = this.images[index].name;
                                    if (this.$refs[refName] && this.$refs[refName][0]) {
                                        this.$refs[refName][0].src = reader.result;
                                    }
                                    this.images[index].data = reader.result;
                                };
                            })(i), false);

                            reader.readAsDataURL(this.images[i]);
                        }
                    }
                }
            } else {
                this.$notification.error("File not supported!", {
                    timer: 3,
                    position: "bottomCenter",
                });
                return null;
            }


        },
        removeImage(i) {
            this.images.splice(i, 1);
        },
        removeExistingImage(id) {
            const indexInExistingImages = this.existingImages.findIndex(img => img.id === id);
            this.existingImages.splice(indexInExistingImages, 1);
        },
        getDetail() {
            axios.get(`leave/detail/${this.$route.params.id}`)
                .then(res => {
                    if (res.data) {
                        this.leaveData = res.data;
                        if(res.data.img_paths){
                            this.existingImages = res.data.img_paths;
                        }
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Admin Detail API Error", error);
                })
            
        },

        update() {
            this.submitted = true;
            if (this.leaveData.from_date && this.leaveData.to_date && new Date(this.leaveData.to_date) < new Date(this.leaveData.from_date)) {
                this.isError = true;
            } else {
                this.isError = false;
            }

            if (this.$v.$invalid || this.isError) {
                return;
            }
            this.leaveData.img_paths = this.existingImages;
            this.leaveData.new_images = this.images;
            this.leaveData.login_id = this.$store.state.user.id;
            axios.post(`leave/edit/${this.$route.params.id}`, this.leaveData)
                .then(res => {
                    if (res.data.success == true) {
                        this.$notification.success("Leave Form Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "leaveList"
                        });
                    } else {
                        this.disabled = false;
                        this.$notification.error('res.data.message', {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        }
    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
    },
};
