import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '../../store';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import CONFIG from "@/assets/js/config.js";

export default {
    name: "CourseList",
    components: {
        MainLayout,
        Select2Multiple,
        Loading,
        flatPickr,
        AdvancedLaravelVuePaginate,
        CONFIG,
    },

    data() {
        return {
            courseData: {},
            removeId: '',
            searchData: {
                acd_year_id: null,
                class_id: null,
                teacher_name: '',
                subject_name: '',
                start_date: null,
                end_date: null
            },
            total: null,
            academicYearArr: null,
            classArr: null,
            teacherArr: null,
            subjectArr: null,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            class_id: '',
        };
    },

    methods: {
        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        getCurrentPeriodList() {
            axios.get('/getAcademicYear', {
                params: {
                    campus_id: store.state.user.campus_id,
                }
            })
            .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select Academic Year'
                }];
                this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
                this.isLoading = false;
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },
        getList(page = 1) {
            axios.get('course/getList?page=' + page,
                {
                    params: {
                        campus_id: this.$store.state.user.campus_id,
                    }
                })
                .then(res => {
                    if (res.data) {
                        this.courseData = res.data;
                        this.total = this.courseData.total;
                        this.isLoading = false;
                    }
                   
                }).catch((error) => {
                    if (!error && error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        search(page = 1) {
            this.isLoading = true;
            axios.get('course/search?page=' + page, {
                params: {
                    class_id: this.searchData.class_id,
                    teacher_name: this.searchData.teacher_name,
                    subject_name: this.searchData.subject_name,
                    start_date: this.searchData.start_date,
                    end_date: this.searchData.end_date,
                    campus_id: this.$store.state.user.campus_id,
                    acd_year_id: this.searchData.acd_year_id,
                }
            })
                .then((res) => {
                    this.isLoading = false;
                    if (res.data) {
                        this.courseData = res.data;
                        this.total = this.courseData.total;
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.delete(id);
            } else {
                console.log("no");
            }
        },

        delete(id) {
            this.isLoading = true;
            axios.delete(`course/delete/${id}`, {
                params: {
                    campus_id: this.$store.state.user.campus_id
                }
            })
                .then(res => {
                    if (res.data.success == true) {
                        this.isLoading = true;
                        this.getList();
                    }
                    this.$notification.success('Course Deleted Successfully', {
                        timer: 3,
                        position: "bottomCenter"
                    });
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        },

        reset() {
            this.searchData.acd_year_id = null;
            this.searchData.class_id = null;
            this.searchData.teacher_name = '';
            this.searchData.subject_name = '';
            this.searchData.start_date = null;
            this.searchData.end_date = null;
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('course/excelDownload', {
                params: {
                    class_id: this.searchData.class_id,
                    teacher_name: this.searchData.teacher_name,
                    subject_name: this.searchData.subject_name,
                    start_date: this.searchData.start_date,
                    end_date: this.searchData.end_date,
                    campus_id: this.$store.state.user.campus_id,
                    acd_year_id: this.searchData.acd_year_id,
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    let date = new Date();
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `course_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showCreate() {
            this.$router.push({
                path: "/course/create"
            });
        },

        showEdit(id) {
            this.$router.push({
                name: "courseEdit",
                params: {
                    id: id
                }
            });
        },

        showDetail(classId) {
            this.$router.push({
                name: "courseDetail",
                params: {
                    id: classId
                }
            });
        },

        changeAcademicYearEvent($acd_year_id) {
            this.searchData.class_id = 0;
            this.getClass($acd_year_id);
        }
    },

    mounted() {
        this.getList();
        this.getCurrentPeriodList();
        
    },
};
