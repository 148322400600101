import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from "axios";
import store from '../../store';
import {
    required,maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "gradingCreate",
    components: {
        MainLayout,
        Select2Multiple,
        flatPickr,
        Loading
    },

    data() {
        return {
            isLoading: false,
            classData: {},
            selectedClass: 0,
            classArr: [],
            subjectArr: [],
            studentNameArr: [],
            academic_year: '',
            academicYear: '',
            class_id: '',
            remark: '',
            gradingSelected: 0,
            exam_name: '',
            exam_start_date: '',
            exam_end_date: '',
            student_id: 0,
            subject_id: '',
            academicYearArr: null,
            gradingArr: null,
            submitted: false,
            items: [],
            comment: [],
            firstname_ErrMsg: [],
            subject_ErrMsg: '',
            comment_msg: [],
            msgCount: 0,
            id: 0,
            cmt: 0,
            cmtCount: 0,
            isError: false,
            noStudentMsg:'',
        };
    },

    validations: {
        class_id: {
            required
        },
        academic_year: {
            required
        },
        student_id: {
            required
        },
        subject_id: {
            required
        },
        exam_start_date: {
            required
        },
        exam_end_date: {
            required
        },
        exam_name: {
            required,
            maxLength: maxLength(100)
        },
        items: {
            $each: {
                required,
                maxLength: maxLength(10)
            }
        },
        comment: {
            $each: {
                maxLength: maxLength(1000)
            }
        }
    },

    methods: {
      getCurrentPeriodList() {
        axios.get('/period/getCurrentPeriodList', {
          params: {
            campus_id: store.state.user.campus_id,
          }
        })
          .then(res => {
            let $defaultSelectOption = [{
              'id': 0,
              'text': 'Select Academic Year'
            }];
            this.academicYearArr = $defaultSelectOption.concat(res.data);
            this.isLoading = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
          });
      },
        
        changeEvent(val, fieldName) {
            this.submitted = false;
            this.$set(this, fieldName, val);
             if (fieldName == "academic_year") {
                this.isLoading = true;                 
                this.classArr = [];
                this.subjectArr = [];
                this.studentNameArr = [];
                this.firstname_ErrMsg= [];
                this.subject_ErrMsg= '';
                this.items = [];
                this.comment = [];
                this.comment_msg= [];
                this.subject_id = -1;
                this.student_id = 0;
                this.class_id = 0;
                this.exam_name = '';
                this.selectedClass = 0;
                 this.getClass(val);
             }
             if (fieldName == "class_id") {
                this.isLoading = true;
                this.subjectArr = [];
                this.studentNameArr = [];
                this.firstname_ErrMsg = [];
                this.subject_ErrMsg = '';
                this.items = [];
                this.comment = [];
                this.comment_msg = [];
                this.subject_id = -1;
                this.student_id = 0;
                this.getStudentName(val);
                this.getSubjectByClassId(val);
             }
        },

        getClass(academic_year) {
            axios.get("class/classList/" + academic_year, {
                    params: {
                        campus_id: store.state.user.campus_id
                    }
                })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        changeEventSubject(val, index) {
            if (val === null || val === '') {
                this.firstname_ErrMsg[index] = 'Please Enter Grading Mark.';
            } else {
                this.firstname_ErrMsg[index] = '';
            }
        },

        getStudentName($classId) {
            axios.get("student/getStudentName/" + $classId, {
                    params: {
                    campus_id: store.state.user.campus_id
                    }
                 })
                 .then(res => {
                  if (res.data.length > 0) {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Student Name'
                    }];
                    this.studentNameArr = $defaultSelectOption.concat(res.data);
                    this.noStudentMsg = '';
                    this.isLoading = false;
                  } else {
                    this.studentNameArr = [];
                    this.noStudentMsg = 'No student for this class.'
                    this.isLoading = false;
                  }
                }).catch((error) => {
                    if (error.response.status == 401) {
                       this.$store.commit('logout');
                       window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getSubjectByClassId($classId) {
            axios
                .get("/class/subjectByClassId/" + $classId, {
                    params: {
                        campus_id: store.state.user.campus_id,
                        acd_year_id: this.academic_year
                    }
                })
                .then((response) => {
                    if (response.data && response.data.subjectArr.length > 0) {
                        this.subjectArr = response.data.subjectArr[0];
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Subject API Error", error);
                });
        },

        confirmRegister() {
            this.msgCount = 0; 
            this.cmtCount = 0;
            this.id = 0;
            this.submitted = true;
            this.isLoading = true;
            if (this.exam_start_date && this.exam_end_date && new Date(this.exam_end_date) < new Date(this.exam_start_date)) {
              this.isError = true;
            } else {
              this.isError = false;
            }
            if (this.$v.$invalid || this.class_id == '' || this.class_id == 0 || this.academic_year == '' || this.academic_year == 0 ||  this.student_id == '' || this.student_id == 0 || this.isError) {
                if(this.subjectArr.length > 0) {
                    this.subjectArr.forEach((value, index) => {
                        this.cmt = this.comment[index] ? this.comment[index].length : 0;
                        if (this.cmt > 1000) {
                            this.comment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.comment_msg[index] = '';
                        }
                        if (this.items[index] == undefined || this.items[index] == 0) {
                            this.firstname_ErrMsg[index] = 'Please Enter Grading Mark.';
                            this.msgCount++;
                        } else if (this.items[index].length > 10) {
                            this.firstname_ErrMsg[index] = "Grading Mark must be at most 10 characters.";
                            this.msgCount++;
                        } else {
                            this.firstname_ErrMsg[index] = '';
                        }
                    });
                    this.subject_ErrMsg = '';
                } else {
                    this.subject_ErrMsg = "Subject Data doesn't exist according to Grade";
                }
                
                if (this.msgCount > 0 || this.cmtCount > 0 || this.subject_ErrMsg != '' || this.isError) {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = false;
                return;
            } else {
                if (this.subjectArr.length > 0) {
                    this.subjectArr.forEach((value, index) => {
                        this.cmt = this.comment[index] ? this.comment[index].length : 0;
                        if (this.cmt > 1000) {
                            this.comment_msg[index] = 'Grading Comment';
                            this.cmtCount++;
                        } else {
                            this.comment_msg[index] = '';
                        }
                        if (this.items[index] == undefined || this.items[index] == 0) {
                            this.firstname_ErrMsg[index] = 'Please Enter Grading Mark.';
                            this.msgCount++;
                        } else if (this.items[index].length > 10) {
                            this.firstname_ErrMsg[index] = "Grading Mark must be at most 10 characters.";
                            this.msgCount++;
                        } else {
                            this.firstname_ErrMsg[index] = '';
                        }
                    });
                    this.subject_ErrMsg = '';
                } else {
                    this.subject_ErrMsg = "Subject Data doesn't exist according to Grade";
                }
                if (this.msgCount > 0 || this.cmtCount > 0 || this.subject_ErrMsg != '') {
                    this.isLoading = false;
                    return;
                }
            }
            this.register();
        },

        register() {
            var request = {
                academic_year: this.academic_year,
                class_id: this.class_id,
                student_id: this.student_id,
                exam_name: this.exam_name,
                grading: this.items,
                gradeComments: this.comment,
                exam_start_date: this.exam_start_date,
                exam_end_date: this.exam_end_date,
                subjects: this.subjectArr,
                campus_id: store.state.user.campus_id,
                created_id: store.state.user.id
            }
            
            axios.post("/grading/create", request, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => {
                    if (response.data.status == 200) {
                        this.isLoading = false;
                        this.$notification.success("Grading Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "gradingList"
                        });
                    }
                    else if (response.data.success == false) {
                        this.isLoading = false;
                        this.$notification.error(response.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                    else if (response.data.status == 201) {
                        this.isLoading = false;
                        this.$notification.error("Grading data is already exist.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    } else {
                        this.isLoading = false;
                        this.$notification.error("something went wrong", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Grading Create API Error", error.errors);
                });
        },

        showList() {
            this.$router.push({
                path: "/grading/list"
            });
        },
    },

    mounted() {
        this.isLoading = true;
        this.getCurrentPeriodList();
    },
};
