import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import store from '../../store';
import axios from "axios";
import {
    required,
    numeric,
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: "gradeFeeCreate",
    components: {
        MainLayout,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            isLoading: false,
            gradeFeeData: {},
            selectedCampusGrade: 0,
            campus_grade_id: 0,
            campusGradeArr: null,
            submitted: false,
            disabled: false,
        };
    },

    validations: {
        campus_grade_id: {
            required
        },
        gradeFeeData: {
            annual_fee: {
                numeric
            },
            monthly_fee: {
                numeric
            },
        },
    },

    methods: {
        changeEvent(val, fieldName) {
            val = ++val;
            this.$set(this, fieldName, val);
        },

        selectEvent({
            id
        }, fieldName) {
            id == 'Select' ? this.$set(this, fieldName, '') : this.$set(this, fieldName, id);
        },

        getCampusGradeName() {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            axios.get("/getAcademicYearCampusGradeName", request).then((response) => {
                let $defaultSelectOption = [{
                    "id": 0,
                    "text": "Select"
                }];
                this.campusGradeArr = $defaultSelectOption.concat(response.data);
                this.isLoading = false;
            })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                });
        },

        confirmRegister() {
            this.submitted = true;
            if (this.$v.$invalid || this.campus_grade_id == '' || this.campus_grade_id == 0) {
                return;
            }
            this.register();
        },

        register() {
            this.disabled = true;
            var request = {
                campus_grade_id: this.campus_grade_id,
                monthly_fee: this.gradeFeeData.monthly_fee,
                annual_fee: this.gradeFeeData.annual_fee,
                login_id: store.state.user.id,
            }

            axios.post("/gradeFee/create", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data.status == 200) {
                        this.$notification.success("Grade Fee Added Successfully", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        if (this.$route.query.rd_back == 'student') {
                            this.$router.push({
                                name: "gradeFeeCreate"
                            });
                        } else {
                            this.$router.push({
                                name: "gradeFeeList"
                            });
                        }
                    } else {
                        this.disabled = false;
                        this.$notification.error(response.data.message, {
                          timer: 3,
                          position: "bottomCenter"
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    if (error.response.status == 422) {
                        this.disabled = false;
                        this.$notification.error(error.response.data.message, {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    }
                    this.disabled = false;
                    console.log("Grade Fee Create API Error:", error.response.data.message);
                });
        },

        showList() {
            this.$router.push({
                path: "/gradeFee/list"
            });
        },
    },

    mounted() {
        this.getCampusGradeName();
        this.isLoading = true;
    },
};
