import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import store from '../../store';
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import {
  required,
  numeric,
} from "vuelidate/lib/validators";
import {
  mapGetters
} from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "gradeFeeEdit",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      disabled: false,
      gradeFeeData: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
      campusGradeArr: null,
      selectedGrade: 0,
      selectedYear: 0,
      academic_year: '',
      grade_id: '',
      submitted: false,
    };
  },

  validations: {
    campus_grade_id: {
      required
    },
    gradeFeeData: {
      annual_fee: {
        numeric
      },
      monthly_fee: {
        numeric
      },
    },
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  methods: {
    getCampusGradeName() {
      axios.get('getAcademicYearCampusGradeName', {
          params: {
              campus_id: store.state.user.campus_id
          }
      }).then(res => {
          let $defaultSelectOption = [{
              "id": 0,
              "text": "Select"
          }];
          this.campusGradeArr = $defaultSelectOption.concat(res.data);
      }).catch((error) => {
          if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
          }
          this.isLoading = false;
      });
  },
    
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
    },

    getGradeFeeEdit() {
      axios.get("/gradeFee/detail/" + this.$route.params.id, {
            params: {
                campus_id: store.state.user.campus_id
            }
        })
        .then(response => {
          this.gradeFeeData = response.data;
          this.selectedGrade = this.campus_grade_id = this.gradeFeeData.campus_grade_id;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Class Detail API Error", error);
        });
    },

    confirmUpdate() {
      this.submitted = true;
      if (this.$v.$invalid || this.campus_grade_id == '' || this.campus_grade_id == 0) {
        return;
      }
      this.update();
    },

    update() {
      this.disabled = true;
      var id = this.$route.params.id
      var request = {
        campus_grade_id: this.campus_grade_id,
        annual_fee: this.gradeFeeData.annual_fee,
        monthly_fee: this.gradeFeeData.monthly_fee,
        login_id: store.state.user.id,
      }
      axios.post("/gradeFee/update/" + id, request, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.data.status == 200) {
            this.$notification.success("Class Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "gradeFeeList"
            });
          } else {
            this.disabled = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
        }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
          console.log("Class Edit API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/gradeFee/list"
      });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getCampusGradeName();
    this.getGradeFeeEdit();
  },
};
